body {
  margin: 0;
}

.responsive-image-container {
  width: 100%;
  position: unset !important;
}
.responsive-image-container img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}
